* {
  margin: 0;
  padding: 0;
}

html {
  scroll-behavior: smooth;
}

/* Desktop styling */
.Screen {
  height: 100vh;
  display: grid;
  grid-template-columns: 40% 60%;
  background-color: #273043;
}

.Box1 {
  background: #9197ae;
  text-align: center;
  grid-column: 1/2;
}

.mobileVector {
  width: 49%;
  margin-top: 10%;
}


.Circles {
  margin-top: 2%;
}

.Circles ul {
  text-align: center;
  list-style: none;
}

.Circles li {
  display: inline-block;
}

#btn1,
#btn2,
#btn3,
#btn4,
#btn5,
#btn6 {
  width: 4.5vw;
}

.Box2 {
  background: #273043;
  text-align: center;
  grid-column: 2/3;
}

.Logo {
  margin-top: 11%;
}

.Image {
  width: 22%;
}

.Name {
  width: 30%;
}

.Description {
  font-family: sans-serif;
  color: #f02d3a;
  margin-top: 7%;
  margin-left: 20%;
  margin-right: 20%;
}

.DescripTXT {
  font-size: 1.2vw;
}

.PlayStore {
  margin-top: 8%;
}

.PlayStoreImg {
  width: 25%;
}


/* Mobile styling */
.MobScreen {
  height: 100vh;
  display: grid;
  grid-template-columns: 100%;
  grid-template-rows: 100vh 100vh;
  background-color: #273043;
}

.FirstHalf {
  text-align: center;
  grid-row: 1/2;
  background: #273043;
}

.Logo2 {
  margin-top: 11%;
}

.Image2 {
  width: 40%;
}



.Name2 {
  width: 70%;
}

.Description2 {
  font-family: sans-serif;
  color: #f02d3a;
  margin-top: 7%;
  margin-left: 5%;
  margin-right: 5%;
}

.DescripTXT2 {
  font-size: 14px;
}

.PlayStore2 {
  margin-top: 20%;
  margin-bottom: 30px;
}

.PlayStoreImg2 {
  width: 40%;
}


.PlayStore2 :active {
  -webkit-tap-highlight-color: transparent;
}

.SecondHalf {
  grid-row: 2/3;
  text-align: center;
  background: #9197ae
}

.Phone2 {
  text-align: center;
  margin-top: 11%;
}

.mobileVector2 {
  width: 58%;
  height: 100%;
  margin-top: 9%;
  margin-bottom: 40px;
}

.Circles2 {}

.Circles2 ul {
  text-align: center;
  list-style: none;
}

.Circles2 li {
  display: inline-block;
  transform: scale(2);
  margin-left: 20px;
  margin-right: 20px;
}

.Drop:focus,
.Drop:active,
.Drop:hover {
  -webkit-tap-highlight-color: transparent;
}